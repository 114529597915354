import React, { useEffect, useState } from 'react';
import { FilloutStandardEmbed } from '@fillout/react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userData } from '../../../redux/actions/userActions';
import { setNewIntegration } from '../../../redux/actions/storesActions';
import useDraftFinder from '../../../hooks/useDraftFinder';

function FilloutForm() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stores = useSelector((state) => state.merchant.stores);
  const draftStore = useDraftFinder();
  const user = useSelector((state) => state.user);
  const integrationsBody = useSelector((state) => state.merchant.integrationsBody);
  const locationPath = useSelector((state) => state.location.path);
  const onboardCode = useSelector((state) => state.location.onboardCode);
  const fromPlatform = useSelector((state) => state.platform);
  const [initialTimer, setInitialTimer] = useState(60);
  const [secondTimer, setSecondTimer] = useState(15);

  const getFilloutId = () => {
    const filloutIds = {
      MX: {
        tiendanube: {
          prod: 'rz9X4EPRhEus',
          dev: 'rz9X4EPRhEus',
        },
        woo: {
          prod: 'rz9X4EPRhEus',
          dev: 'rz9X4EPRhEus',
        },
        generic: {
          prod: 'rz9X4EPRhEus',
          dev: 'rz9X4EPRhEus',
        },
      },
      CO: {
        tiendanube: {
          prod: 'tj8LhBHDtaus',
          dev: '2E8tUFJ8ELus',
        },
        woo: {
          prod: 'tj8LhBHDtaus',
          dev: 'j7iePz5F6sus',
        },
        generic: {
          prod: 'tj8LhBHDtaus',
          dev: 'j7iePz5F6sus',
        },
      },
    };

    const country = draftStore?.country_name === 'Mexico' ? 'MX' : 'CO';

    if (!onboardCode && !fromPlatform) {
      return env === 'production' ? filloutIds[country].generic.prod : filloutIds[country].generic.dev;
    }

    if (onboardCode && fromPlatform !== 'woocommerce') {
      return env === 'production' ? filloutIds[country].tiendanube.prod : filloutIds[country].tiendanube.dev;
    }

    if (fromPlatform === 'woocommerce' && !onboardCode) {
      return env === 'production' ? filloutIds[country].woo.prod : filloutIds[country].woo.dev;
    }

    // Default fallback
    return filloutIds[country].generic[env === 'production' ? 'prod' : 'dev'];
  };

  useEffect(() => {
    if (draftStore) {
      dispatch({
        type: 'SET_DRAFT_STORE',
        payload: draftStore,
      });
    }
    if (locationPath === '/onboard') {
      dispatch({ type: 'RESET_LOCATION' });
    }
  }, []);

  useEffect(() => {
    const intId = setInterval(() => {
      if (draftStore) {
        if (initialTimer > 0) {
          setInitialTimer(initialTimer - 1);
        }
        if (initialTimer === 0 && secondTimer === 15) {
          dispatch(userData());
        }
        if (initialTimer === 0 && secondTimer > 0) {
          setSecondTimer(secondTimer - 1);
        }
        if (secondTimer === 0) {
          setSecondTimer(15);
        }
      } else if (!fromPlatform) {
        navigate('/success');
      }
    }, 1000);

    if (!draftStore && fromPlatform) {
      dispatch(setNewIntegration(stores[stores.length - 1]?.id_store, integrationsBody, navigate));
    }

    return () => {
      clearInterval(intId);
    };
  }, [initialTimer, secondTimer]);

  return (
    <Grid width="100%" minHeight="100%">
      <FilloutStandardEmbed
        filloutId={getFilloutId()}
        parameters={{
          id_store: draftStore?.id_store,
          merchant: draftStore?.store_name,
          phone: draftStore?.phone_number,
          website: draftStore?.store_url,
          rut: draftStore?.store_nit,
          email: user?.email,
          platform: fromPlatform,
        }}
      />
    </Grid>
  );
}

export default FilloutForm;
