/* eslint-disable react/jsx-indent */
import AWS from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import {
  Grid, Stack, Typography, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import Flag from 'react-world-flags';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import {
  ButtonStyled, CountrySelect, FormStyled, ReturnButton, StoreLogoContainer, StoreLogoImage, TextInputStyled,
} from '../../Global.styled';
import COUNTRIES from '../../../utils/countries';
import { createNewStore } from '../../../redux/actions/storesActions';

export const NewStoreContainer = styled(Grid)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  maxHeight: '80vh',
  height: '100%',
  overflow: 'scroll',
  gap: '1rem',
  padding: '6rem 6rem 4rem 6rem',
  '@media (max-width: 768px)': {
    width: 'fit-content',
    padding: '4rem 2rem',
    maxHeight: '100%',
    overflow: 'unset',
  },
}));

function NewStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['login']);
  const user = useSelector((state) => state.user);
  const stores = useSelector((state) => state.merchant.stores);
  const integrationsBody = useSelector((state) => state.merchant.integrationsBody);
  const geo = localStorage.getItem('geo');
  const loadingStoreLogo = useSelector((state) => state.merchant.loadingStoreLogo);
  const loadingStores = useSelector((state) => state.merchant.loadingStores);
  const countries = Country.getAllCountries();
  const [selectedCountryCode, setSelectedCountryCode] = useState(geo);
  const [phoneCountry, setPhoneCountry] = useState(geo || 'CO');
  // const phoneDialCode = selectedCountryCode ? COUNTRIES[selectedCountryCode]?.dialCode : '+57';
  const referralCode = useSelector((state) => state.location.referralCode);
  const [storeURLError, setStoreURLError] = useState(false);

  const [formData, setFormData] = useState({
    store_name: '',
    store_url: '',
    country_name: '',
    currency: '',
    store_logo: '',
    store_nit: '',
    phone_number: '',
    referral_code: referralCode || '',
    referred_by: '',
  });

  const [submitDisabled, setSubmitDisabled] = useState(
    formData.store_name === ''
    || formData.store_url === ''
      || formData.country_name === ''
      || formData.currency === ''
      || formData.store_nit === ''
      || formData.phone_number === '',
  );

  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState('https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg');

  const s3k = process.env.REACT_APP_S3_K;
  const s3sk = process.env.REACT_APP_S3_SK;
  const S3_BUCKET = 'wava-stores';
  const REGION = 'us-east-1';

  AWS.config.update({
    accessKeyId: s3k,
    secretAccessKey: s3sk,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = async () => {
    dispatch({
      type: 'SET_LOADING_STORE_LOGO',
      payload: true,
    });
    const params = {
      Bucket: S3_BUCKET,
      Key: `preview/${user?.id_user}/${stores?.length}/${file.name}`,
      Body: file,
    };

    const upload = s3
      .putObject(params)
      .on('httpUploadProgress', (e) => {
        // eslint-disable-next-line no-console
        console.log(`Uploading logo ${parseInt((e.loaded * 100) / e.total, 10)}%`);
      })
      .promise();

    try {
      await upload.then(() => {
        dispatch({
          type: 'SET_LOADING_STORE_LOGO',
          payload: false,
        });
        setFormData((prev) => ({
          ...prev,
          store_logo: `https://${S3_BUCKET}.s3.amazonaws.com/preview/${user?.id_user}/${stores?.length}/${file.name}`,
        }));
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: t('Logotipo actualizado'),
          kind: 'success',
        },
      });
    } catch {
      dispatch({
        type: 'SET_LOADING_STORE_LOGO',
        payload: false,
      });
      dispatch({
        type: 'SET_MESSAGE',
        payload: {
          text: t('Error al cargar el logo de la tienda'),
          kind: 'error',
        },
      });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImgPreview(URL.createObjectURL(e.target.files[0]));
    }
    setFile(e.target.files[0]);
  };

  const handleCancelChanges = () => {
    document.getElementById('storeLogo').value = '';
    setImgPreview('https://wava-assets.s3.amazonaws.com/wava-dashboard/section-settings/icon-storefront.svg');
    setFormData((prev) => ({
      ...prev,
      store_logo: '',
    }));
    setFile(null);
  };

  const [nitLabel, setNitLabel] = useState('NIT/RUT');
  const availableCountries = [
    { isoCode: 'CO', name: 'Colombia' },
    { isoCode: 'MX', name: 'Mexico' },
  ];

  const handlePhoneCountrySelect = (value) => {
    setPhoneCountry(value);
  };

  const handleCountrySelect = (value) => {
    const countryCurrency = countries.find((c) => c.isoCode === value)?.currency;
    setSelectedCountryCode(value);
    setFormData((prev) => ({
      ...prev,
      country_name: countries.find((c) => c.isoCode === value)?.name || '',
      currency: countryCurrency || '',
    }));

    // Update NIT label based on selected country
    switch (value) {
      case 'CO':
        setNitLabel('NIT/RUT');
        break;
      case 'MX':
        setNitLabel('RFC');
        break;
      default:
        setNitLabel('Tax ID');
    }
  };

  const handleFormData = (e) => {
    const hasRegex = e.target?.dataset?.regex && new RegExp(e.target?.dataset?.regex);
    const validRegex = hasRegex?.test(e.target.value);
    if (!hasRegex || (hasRegex && validRegex)) {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleURLValidation = (e) => {
    const fixedURL = `https://${e.target.value}`;
    if (fixedURL.length > 12) {
      if (/^(http(s?):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(fixedURL)) {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]: fixedURL,
        }));
        setStoreURLError(false);
      } else {
        setFormData((prev) => ({
          ...prev,
          [e.target.name]: '',
        }));
        setStoreURLError(true);
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: '',
      }));
      setStoreURLError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createNewStore(
        {
          ...formData,
          ...integrationsBody,
        },
        navigate,
      ),
    );
  };

  useEffect(() => {
    if (!formData.country_name) {
      handleCountrySelect(phoneCountry);
    }
    if (
      formData.store_name === ''
      || formData.store_url === ''
      || formData.country_name === ''
      || formData.currency === ''
      || formData.store_nit === ''
      || formData.phone_number === ''
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [formData]);

  return (
    <NewStoreContainer>
      <ReturnButton onClick={() => navigate(-1)}>
        <ChevronLeft fontSize="inherit" />
        Regresar
      </ReturnButton>
      <Typography variant="h3">{t('Crea una tienda nueva')}</Typography>
      <Typography variant="body1">{t('Regístrate ahora y comienza a crecer con nosotros. ¡Es rápido y fácil!')}</Typography>
      <Typography variant="body1" fontWeight="bold">
        {t('Te tomará menos de 5 minutos')}
      </Typography>
      <FormStyled style={{ marginTop: '1rem', gap: '1.5rem' }} onSubmit={(e) => handleSubmit(e)}>
        <TextInputStyled
          name="store_name"
          type="text"
          label={t('Nombre de la tienda')}
          required
          disabled={loadingStores}
          helperText={t('Nombre con el que te conocen tus clientes.')}
          onChange={(e) => handleFormData(e)}
          InputLabelProps={{ shrink: true }}
        />
        <TextInputStyled
          name="phone_number"
          type="text"
          label={t('Teléfono')}
          required
          disabled={loadingStores}
          value={formData?.phone_number}
          placeholder={t('0-000-000')}
          helperText={t('Número de contacto de Whatsapp de tu tienda.')}
          onChange={(e) => handleFormData(e)}
          InputLabelProps={{ shrink: true }}
          inputMode="numeric"
          InputProps={{
            inputProps: { 'data-regex': '^[0-9]*$' },
            startAdornment: (
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '3rem',
                  marginRight: phoneCountry && COUNTRIES[phoneCountry]?.dialCode ? '3rem' : '0.5rem',
                  gap: '0.5rem',
                }}
              >
                {phoneCountry && <Flag code={phoneCountry} width="30" fallback="" />}
                <CountrySelect name="phone_country" label="phone_country" onChange={(e) => handlePhoneCountrySelect(e.target.value)}>
                  <option label="country_option" value="" style={{ display: 'none' }} />
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </CountrySelect>
                {COUNTRIES[phoneCountry]?.dialCode && <strong>{COUNTRIES[phoneCountry].dialCode}</strong>}
              </Grid>
            ),
          }}
        />
        <TextInputStyled
          name="store_url"
          type="text"
          label={t('Sitio web')}
          required
          disabled={loadingStores}
          error={storeURLError}
          placeholder="ejemplo.com"
          helperText={
            storeURLError ? 'Tu sitio web debe cumplir con la sintaxis correcta. Ej: "https://ejemplo.com"' : t('Dirección web de tu tienda.')
          }
          onChange={(e) => handleURLValidation(e)}
          InputProps={{
            startAdornment: <Typography variant="body1">https://</Typography>,
          }}
          InputLabelProps={{ shrink: true }}
        />

        <TextInputStyled
          select
          name="country_name"
          label={t('Country')}
          required
          disabled={loadingStores}
          value={selectedCountryCode}
          onChange={(e) => handleCountrySelect(e.target.value)}
          InputLabelProps={{ shrink: true }}
        >
          {availableCountries.map((country) => (
            <MenuItem key={country.isoCode} value={country.isoCode}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Flag code={country.isoCode} width="20" fallback="" />
                <span>{country.name}</span>
              </Stack>
            </MenuItem>
          ))}
        </TextInputStyled>

        <TextInputStyled
          name="store_nit"
          type="text"
          value={formData?.store_nit}
          required
          disabled={loadingStores}
          label={t(nitLabel)}
          helperText={t('No uses puntos ni comas.')}
          onChange={(e) => handleFormData(e)}
          inputMode="text"
          inputProps={{ 'data-regex': '^[0-9]*$' }}
          InputLabelProps={{ shrink: true }}
        />
        <Stack gap={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Carga el logotipo de tu tienda
          </Typography>
          <Stack direction="row" gap={2} alignItems="center" sx={{ flexWrap: 'wrap' }}>
            <StoreLogoContainer>
              <StoreLogoImage src={imgPreview} alt="Store logo" />
            </StoreLogoContainer>
            <input type="file" id="storeLogo" accept="image/*,.png" onChange={(e) => handleFileChange(e)} />
          </Stack>
          <Typography variant="caption">{t('Te recomendamos que uses imágenes en formato PNG o JPG de mínimo 256×256 píxeles.')}</Typography>
          <Stack direction="row" gap={2}>
            <ButtonStyled variant="outlined" disabled={!file || loadingStoreLogo || loadingStores} onClick={() => handleCancelChanges()}>
              {t('Cancelar')}
            </ButtonStyled>
            <LoadingButton
              disabled={!file || loadingStores}
              loading={loadingStoreLogo}
              sx={{ borderRadius: '6rem' }}
              variant="contained"
              onClick={() => uploadFile()}
            >
              {t('Guardar')}
            </LoadingButton>
          </Stack>
        </Stack>
        <LoadingButton
          disabled={submitDisabled || loadingStoreLogo}
          loading={loadingStores}
          type="submit"
          variant="contained"
          size="large"
          sx={{ alignSelf: { sm: 'flex-end' }, borderRadius: '6rem' }}
          onClick={(e) => handleSubmit(e)}
          onSubmit={(e) => handleSubmit(e)}
        >
          {t('Siguiente')}
        </LoadingButton>
      </FormStyled>
    </NewStoreContainer>
  );
}

export default NewStore;
